<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Step Indicator</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              A Step Indicator – also called a progress tracker or step-based
              progress bar – updates users on their progress through a
              multi-step process, reducing uncertainty as they complete each
              screen.
            </p>
            <p>
              Progress Trackers lead users through complex processes, allowing
              them to feel less overwhelmed, expend less cognitive energy, and
              make fewer mistakes.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Use a Step Indicator for long or complex forms or processes
                    that span several steps that must be performed in a linear
                    progression.
                  </li>
                  <li>
                    Do not use a Step Indicator for forms where the number of
                    steps might change due to conditional logic, for nonlinear
                    forms or processes, or for very short forms.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>

            <section id="usability">
              <div class="content-band">
                <h2>Usability</h2>
                <ul class="bullet-red">
                  <li>
                    Use a contrast color and/or bold text to emphasize the
                    current step. Pending steps should be the least visually
                    prominent, in grey, but still maintain accessible contrast.
                  </li>
                  <li>
                    Label steps using short, succinct, and plain language.
                  </li>
                  <li>
                    Provide navigation, e.g., Next, Previous, Cancel buttons, to
                    allow users to move through the process.
                  </li>
                  <li>
                    Provide a semantic heading for each step and display the
                    step number and total to the left of the heading to help the
                    user keep track of their location in the overall process.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>

            <section id="accessibility">
              <div class="content-band">
                <h2>Accessibility</h2>
                <ul class="bullet-red">
                  <li>
                    Ensure headers are semantic. Our default code uses an
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >&lt;h3&gt;</span
                    >, but use the correct heading level in your implementation.
                  </li>
                  <li>
                    Use
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >aria-label="progress"</span
                    >
                    on
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >class="step-container"</span
                    >
                    to provide important context to screen readers.
                  </li>
                  <li>
                    Use visually hidden text
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >&lt;span
                      class="sr-only"&gt;completed&lt;&sol;span&gt;</span
                    >
                    and
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >&lt;span class="sr-only"&gt;not
                      completed&lt;&sol;span&gt;</span
                    >
                    to alert screen reader users to the completion status of
                    each step.
                  </li>
                  <li>
                    Use  <span class="red-100 plr-5 rounded-4 font-red"
                      >aria-current="true"</span> on the list item representing the
                    current step.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>

            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="stepper-css" />
              </div>
            </section>
            <div class="divider"></div>

            <section id="stepper-indicator-icons">
              <div class="content-band">
                <h3>Stepper Indicator - Icons</h3>
                <CodeView component="stepper" type="stepper-indicator-icons">
                  <StepperComponent id="stepper-icons" />
                </CodeView>
              </div>
            </section>
            <section id="stepper-indicator-numbers">
              <div class="content-band">
                <h3>Stepper Indicator - Numbers</h3>
                <CodeView component="stepper" type="stepper-indicator-numbers">
                  <StepperComponent id="stepper-numbers"
                /></CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import StepperComponent from "../Stepper/StepperComponent";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
import StickyTOC from "../../StickyTOC";
export default {
  name: "Stepper",
  data: function() {
    return {};
  },
  components: {
    StepperComponent,
    CodeView,
    TempLeftNav,
    SubFooter,
    ImportLinkComponent,
    StickyTOC,
  },
};
</script>
