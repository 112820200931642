<template>
  <div class="page-container">
    <section>
      <div class="content-band max-w1000">
        <div class="container" v-if="id === 'list-1'">
          <ul class="ds-list bullet-red">
            <li>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.

              <ul class="sublist" title="sub menu">
                <li>Temporibus atque soluta eius architecto.</li>
                <li>Nulla incidunt dicta numquam ipsam alias modi.</li>
                <li>
                  Aliquam vestibulum morbi blandit cursus risus at ultrices mi.
                </li>
              </ul>
            </li>
            <li>
              Amet dictum sit amet justo donec enim diam vulputate ut.
            </li>

            <li>
              Iaculis urna id volutpat lacus laoreet non curabitur gravida.
            </li>
          </ul>
        </div>
        <div class="container" v-if="id === 'list-2'">
          <ul class="bullet-red ds-list list-bg">
            <li class="gray-100 hover-white hover-shadow">
              Velit ut tortor pretium viverra. Justo nec ultrices dui sapien
              eget mi proin sed.
            </li>
            <li class="gray-100 hover-white hover-shadow">
              Iaculis urna id volutpat lacus laoreet non curabitur gravida.
            </li>

            <li class="hover-white hover-shadow red-100">
              Ornare arcu odio ut sem nulla. Aliquet sagittis id consectetur
              purus ut faucibus pulvinar elementum.
            </li>
          </ul>
        </div>
        <div class="container" v-if="id === 'list-3'">
          <ol>
            <li class="">
              Augue mauris augue neque gravida in. Sed faucibus turpis in eu mi
              bibendum neque egestas congue.
            </li>

            <li class="">
              Velit ut tortor pretium viverra. Justo nec ultrices dui sapien
              eget mi proin sed.
            </li>

            <li class="">
              Ut etiam sit amet nisl. Maecenas volutpat blandit aliquam etiam
              erat.
            </li>
          </ol>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ListComponent",
  props: ["id"],
};
</script>
