<template>
  <div class="page-container">
    <section>
      <div class="content-band max-w1000">
        <div class="container mtb-20">
        </div>
        <div class="divider mlr-0"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "DividerComponent",
  props: ["type"],
};
</script>