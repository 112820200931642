<template>
  <div class="page-container">
    <section class="hover-white" v-if="id === 'stepper-icons'">
      <div class="content-band flex max-w1200">
        <div class="container">
          <div class="step-container ptb-20" aria-label="progress">
            <ul
              class="step-indicator__segments step--icon steps-red-600 marker-icon"
            >
              <li
                class="step-indicator__segment step-indicator__segment--complete"
              >
                <span class="step-indicator__segment-label">
                  Email Verification
                  <span class="sr-only">completed</span>
                </span>
              </li>

              <li
                class="step-indicator__segment step-indicator__segment--complete"
              > 
                <span class="step-indicator__segment-label">
                  Personal Information
                  <span class="sr-only">completed</span>
                </span>
              </li>

              <li
                class="step-indicator__segment step-indicator__segment--current"
              >
                <span class="step-indicator__segment-label">
                  Pick a Username
                </span>
              </li>

              <li class="step-indicator__segment">
                <span class="step-indicator__segment-label">
                  Create Password
                  <span class="sr-only">not completed</span>
                </span>
              </li>

              <li class="step-indicator__segment">
                <span class="step-indicator__segment-label">
                  Account Recovery
                  <span class="sr-only">not completed</span>
                </span>
              </li>
            </ul>

            <div class="step-indicator__header">
              <div class="step-indicator__heading inline-flex">
                <span
                  class="step-indicator__heading-counter plr-10 ptb-5 gray-300 rounded-full"
                >
                  <span class="sr-only">Step</span>
                  <span class="step-indicator__current-step">3 </span>
                  <span class="step-indicator__total-steps"> of 5</span>
                </span>
                <h3 class="step-indicator__heading-text"
                  >Pick a Username</h3
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hover-white" v-if="id === 'stepper-numbers'">
      <div class="content-band flex max-w1200">
        <div class="container">
          <div class="step-container ptb-20" aria-label="progress">
            <ol class="step-indicator__segments step--number steps-blue-800">
              <li
                class="step-indicator__segment step-indicator__segment--complete"
              >
                <span class="step-indicator__segment-label">
                  Email Verification
                  <span class="sr-only">completed</span>
                </span>
              </li>

              <li
                class="step-indicator__segment step-indicator__segment--complete"
              >
                <span class="step-indicator__segment-label">
                  Personal Information
                  <span class="sr-only">completed</span>
                </span>
              </li>

              <li
                class="step-indicator__segment step-indicator__segment--current"
              >
                <span class="step-indicator__segment-label">
                  Pick a Username
                </span>
              </li>

              <li class="step-indicator__segment">
                <span class="step-indicator__segment-label">
                  Create Password
                  <span class="sr-only">not completed</span>
                </span>
              </li>

              <li class="step-indicator__segment">
                <span class="step-indicator__segment-label">
                  Account Recovery
                  <span class="sr-only">not completed</span>
                </span>
              </li>
            </ol>

            <div class="step-indicator__header">
              <div class="step-indicator__heading inline-flex">
                <span
                  class="step-indicator__heading-counter plr-10 ptb-5 gray-300 rounded-full"
                >
                  <span class="sr-only">Step</span>
                  <span class="step-indicator__current-step">3 </span>
                  <span class="step-indicator__total-steps"> of 5</span>
                </span>
                <h3 class="step-indicator__heading-text">
                  Pick a Username</h3
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "StepperComponent",
  props: ["id"],
};
</script>
