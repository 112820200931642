<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Divider</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              A divider is a thin horizontal line that provides visual
              separation between content elements or sections of a page.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Dividers can be between 1-5 or 10 pixels in height.
                  </li>
                  <li>
                    Dividers display a background color. The default divider is
                    set to black with 12% opacity, though any of the
                    <router-link
                      to="/design-system/utilities/colors"
                      class="underline"
                    >
                      Ohio Design System utility colors
                    </router-link>
                    can be used.
                  </li>
                  <li>
                    Dividers should be subtle. To visually reduce the impact of
                    larger dividers, a lighter, more neutral tone should be
                    applied.
                  </li>
                  <li>
                    When placed between section tags, dividers will span full
                    width. To limit a divider to the content area of your page,
                    us the max-width utility of 1440px.
                  </li>
                  <li>
                    A divider tag alone cannot be used to create space between
                    content. Additional
                    <router-link
                      to="/design-system/utilities/size-&-spacing"
                      class="underline"
                    >
                      padding and margin utility classes
                    </router-link>
                    must be added.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="divider-css" />
              </div>
            </section>
            <div class="divider"></div>

            <section id="default-divider">
              <div class="content-band">
                <h3>Default Divider</h3>
                <CodeView component="divider" type="divider">
                  <DividerComponent type="primary" />
                </CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import DividerComponent from "../Divider/DividerComponent";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
import StickyTOC from "../../StickyTOC";
export default {
  name: "Dividers",
  data: function() {
    return {
      primary: {
        main: {
          codeHTML: `<section>
      <div class="content-band max-w1000">
        <div class="container mtb-30">
          <h4>Divider default</h4>
        </div>
        <div class="divider"></div>
    </section>`,
          codeCSS: ``,
          codeJS: "// No JS needed",
          preview: "This is the preview",
          name: "Accordion | Standard",
        },
      },
    };
  },
  components: {
    CodeView,
    DividerComponent,
    TempLeftNav,
    SubFooter,
    ImportLinkComponent,
    StickyTOC,
  },
};
</script>
