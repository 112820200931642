<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>List</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Bulleted lists support efficient reading on the web, highlighting
              important content and making it easy for users to scan the page.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Use an <b>unordered list</b> to display list items in no
                    specific order.
                  </li>
                  <li>
                    Use an <b>ordered list</b> only when sequence or count of
                    items is important.
                  </li>
                  <li>
                    If you need to communicate via long narrative writing, don’t
                    use a list.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>
            <section id="usability-guidelines">
              <div class="content-band">
                <h2>Usability Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Use sentence case and begin lists with a capital letter.
                  </li>
                  <li>
                    List items should be related and have a similar level of
                    importance.
                  </li>
                  <li>
                    Write list items to be similar in line length and phrasing.
                  </li>
                  <li>
                    Keep formatting consistent, using either all sentences or
                    all fragments.
                  </li>
                </ul>
              </div>
            </section>
            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="list-css" />
              </div>
            </section>
            <div class="divider"></div>

            <section id="default-unordered-list">
              <div class="content-band">
                <h3>Default Unordered List</h3>
                <p>
                  By default, unordered lists have black disc bullets with black
                  discs for nested lists. Use utilities
                  <span class="plr-5 font-red red-100 rounded-4"
                    >.bullet-red</span
                  >,
                  <span class="plr-5 font-red red-100 rounded-4"
                    >.bullet-green</span
                  >,
                  <span class="plr-5 font-red red-100 rounded-4"
                    >.bullet-blue</span
                  >
                  for custom color bullets.
                </p>
                <CodeView component="list" type="default-list">
                  <ListComponent id="list-1" />
                </CodeView>
              </div>
            </section>
            <section id="unordered-list-with-background">
              <div class="content-band">
                <h3>Unordered List with Background</h3>
                <p>
                  To attract attention to high-priority content, utility
                  <span class="plr-5 font-red red-100 rounded-4">.list-bg</span>
                  can be used to customize a background color for list items.
                </p>
                <CodeView component="list" type="list-with-background">
                  <ListComponent id="list-2" />
                </CodeView>
              </div>
            </section>
            <section id="default-ordered-list">
              <div class="content-band">
                <h3>Default Ordered List</h3>
                <CodeView component="list" type="list-ordered">
                  <ListComponent id="list-3" />
                </CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import ListComponent from "../List/ListComponent";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
import StickyTOC from "../../StickyTOC";
export default {
  name: "List",
  data: function() {
    return {};
  },
  components: {
    CodeView,
    ListComponent,
    TempLeftNav,
    SubFooter,
    ImportLinkComponent,
    StickyTOC,
  },
};
</script>
